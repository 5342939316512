<template>
  <div class="vtour-attr-pannel">
    <div class="title">
      <span>详细信息</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methodds: {},
};
</script>

<style lang="less" scoped>
.vtour-attr-pannel {
  width: 100%;
  height: 100%;
  background: url(../../../../../assets/images/single/jsc/bg_vtour.png)
    no-repeat;
  background-size: 100% 100%;
  .title {
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    position: relative;
    .el-icon-close {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>